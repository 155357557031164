import { ProfileContext } from 'application/adapters/context/Profile';
import { useImpersonateId } from 'application/hooks/useImpersonateId';
import { Arrangement, getSignedUpArrangements } from 'application/repositories/arrangementsRepository';
import { Profiles } from 'application/repositories/profilesRepository';
import { getCMSHostname } from 'helpers/getCMSHostname';
import logger from 'helpers/logger';
import { useContext, useEffect, useState } from 'react';
import { WelcomeCardProfile } from 'ui/components';

export const WelcomeCardProfileFeature: React.FC<Umbraco.WelcomeCardProfile> = ({ content }) => {
	const [profiles, setProfiles] = useState<Profiles>(null);
	const [arrangements, setArrangements] = useState<Arrangement[]>([]);

	const impersonateId = useImpersonateId();

	useEffect(() => {
		getSignedUpArrangements(getCMSHostname(), impersonateId)
			.then((arrangements) => {
				arrangements?.length > 0 && setArrangements(arrangements.slice(0, 2));
			})
			.catch((error) => {
				logger.error(error);
			});
	}, [impersonateId]);

	const profileContext = useContext(ProfileContext);
	const profile = profileContext.profile;
	const departments = profileContext.branches;

	useEffect(() => {
		if (profile) {
			setProfiles(profile);
		}
	}, [profile]);

	const myBranch = departments?.branches && profile?.branch?.name ? departments?.branches.find((x) => x.value === profile?.branch?.name) : null;
	const branchUrl = myBranch?.memberPortalUrl;

	return <WelcomeCardProfile {...content?.properties} profiles={profiles} arrangements={arrangements} branchUrl={branchUrl} />;
};
