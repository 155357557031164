import { getArrangementsAdapter, GetArrangementsResult, getSignedUpArrangementsAdapter } from 'application/adapters/arrangements/arrangementsAdapter';
export type Arrangement = {
	id?: number;
	title?: string;
	date?: string;
	time?: string;
	location?: string;
	infoText?: string;
	url?: string;
	labels?: Array<{
		name?: string;
		hexCode?: string;
	}> | null;
};

export const getArrangements = async (hostname: string, personalize: boolean, impersonateId?: string): Promise<Arrangement[]> => {
	const arrangements = await getArrangementsAdapter(hostname, personalize, 3, impersonateId);
	return arrangements?.length > 0 ? mapArrangements(arrangements, false) : [];
};

export const getSignedUpArrangements = async (hostname: string, impersonateId?: string): Promise<Arrangement[]> => {
	const arrangements = await getSignedUpArrangementsAdapter(hostname, 999, impersonateId);
	return arrangements?.length > 0 ? mapArrangements(arrangements, true) : [];
};

// map over the array of arrangements and return a new array of simplified arrangements, with some fields formatted for display.
export const mapArrangements = (arrangements: GetArrangementsResult, isSignedUpArrangements: boolean): Arrangement[] => {
	return arrangements.map((arrangement) => {
		const startDate = arrangement.start && new Date(arrangement.start);
		const endDate = arrangement.end && new Date(arrangement.end);
		const isMultipleDays = startDate && endDate && startDate.getDate() !== endDate.getDate();
		const registrationDeadline = arrangement.registrationDeadline && new Date(arrangement.registrationDeadline);

		const startDateString = startDate
			? startDate.toLocaleDateString('da-DK', {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric',
			  })
			: null;

		const endDateString = endDate
			? endDate.toLocaleDateString('da-DK', {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric',
			  })
			: null;

		const startTimeString = startDate
			? startDate.toLocaleTimeString('da-DK', {
					hour: '2-digit',
					minute: '2-digit',
			  })
			: null;
		const endTimeString = endDate
			? endDate.toLocaleTimeString('da-DK', {
					hour: '2-digit',
					minute: '2-digit',
			  })
			: null;
		const labelsToDisplayInPrioritizedOrder = [
			'DEF Ungdom',
			'Fagligt',
			'Tillidsvalgt',
			'Socialt',
			'Virksomhedsbesøg',
			'Ekstern arrangør',
			'Kursus',
			'Uddannelse',
			'Senior',
			'Online arrangement',
		];

		const selectedLabels = arrangement?.categories
			?.filter((category) => labelsToDisplayInPrioritizedOrder.includes(category?.label)) // this filter returns the categories in the prioritized order
			.map((category, index) => {
				return {
					name: category?.label,
					hexCode: category?.label === 'DEF Ungdom' ? '#0B8265' : index == 0 ? '#000064' : '#f87c56',
				};
			});

		return {
			id: arrangement.id,
			title: arrangement.title,
			date: isMultipleDays ? `${startDateString} - ${endDateString}` : startDateString ? startDateString : null,
			time: isMultipleDays
				? `${startDate.toLocaleDateString('da-DK', { weekday: 'short' })} kl. ${startTimeString && startTimeString} ${
						endTimeString && ' - '
				  } ${endDate.toLocaleDateString('da-DK', { weekday: 'short' })} kl. ${endTimeString}
				  `
				: `kl. ${startTimeString && startTimeString} ${endTimeString && ' - ' + endTimeString}`,
			location: arrangement?.location?.title,
			infoText: isSignedUpArrangements
				? 'Du er allerede tilmeldt!'
				: registrationDeadline &&
				  `Seneste tilmelding: ${registrationDeadline?.toLocaleDateString('da-DK', {
						day: '2-digit',
						month: 'long',
				  })} kl. ${registrationDeadline?.toLocaleTimeString('da-DK', { hour: '2-digit', minute: '2-digit' })}`,
			url: arrangement?.url,
			labels: selectedLabels?.slice(0, 2),
		};
	});
};
