import axios from 'axios';
import { getAccessTokenBrowser } from 'helpers/auth';
import { getApiUrl } from 'helpers/getApiUrl';

export type RequestHeaders = {
	hostname: string;
	Authorization?: string;
	impersonateId?: string;
};

// Generated by https://quicktype.io

export type GetTagBasedNewsResult = Array<{
	id: number;
	name: string;
	url: string;
	properties: {
		pageSections: any[];
		pageFooter: any[];
		pageSettings: any[];
		title: string;
		description: string;
		noIndex: boolean;
		noFollow: boolean;
		breadCrumbOn: boolean;
		newsDate: string;
		updatedNewsDate: string;
		newsKicker: string;
		newsHeading: string;
		newsTeaser: string;
		newsAuthor: {
			id: number;
			culture: string;
			name: string;
			url: string;
			documentType: string;
			template: string;
			createDate: string;
			sortOrder: number;
			properties: {
				fullName: string;
				workTitle: string;
				email: string;
				bio: string;
			} | null;
			authResult?: number;
			children?: any[];
		};
		image: Umbraco.Image;
		accessableSites: Array<{
			createDate: string;
			culture: string;
			documentType: string;
			id: number;
			name: string;
			properties: null;
			sortOrder: number;
			template: string;
			url: string;
		}>;
		canonicalUrl: string;
	};
}>;

// Helper function to handle the API call
const fetchNews = async (
	endpoint: string,
	hostname: string,
	tags?: number[] | null,
	count = 999,
	personalize = false,
	impersonateId?: string,
): Promise<GetTagBasedNewsResult> => {
	const accessToken = await getAccessTokenBrowser();
	const headers: RequestHeaders = {
		hostname,
	};

	if (accessToken) {
		headers.Authorization = `Bearer ${accessToken}`;
	}

	if (impersonateId) {
		headers.impersonateId = impersonateId;
	}
	const queryParams = [tags ? `tags=${tags.join(',')}` : null, `limit=${count}`, personalize ? 'personalize=true' : null, impersonateId ? `impersonateId=${impersonateId}`:null ].filter(Boolean).join('&');


	const { href } = getApiUrl(`${endpoint}?${queryParams}`);

	const { data: news } = await axios.get<GetTagBasedNewsResult>(href, { headers });
	return news;
};

export const getTagBasedNewsAdapter = async (
	hostname: string,
	tags?: number[] | null,
	count = 999,
	impersonateId?: string,
): Promise<GetTagBasedNewsResult> => {
	return fetchNews('news/GetNews', hostname, tags, count, false, impersonateId);
};
export const getPersonalizedTagBasedNewsAdapter = async (
	hostname: string,
	tags?: number[] | null,
	count = 999,
	impersonateId?: string,
): Promise<GetTagBasedNewsResult> => {
	return fetchNews('news/GetNewsPersonalized', hostname, tags, count, true, impersonateId);
};
