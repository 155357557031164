import { useImpersonateId } from 'application/hooks/useImpersonateId';
import { getPersonalizedTagBasedNews, getTagBasedNews } from 'application/repositories/tagBasedNewsRepository';
import { getCMSHostname } from 'helpers/getCMSHostname';
import { useEffect, useState } from 'react';
import { NewsList } from 'ui/components';

/*
 *
 *
 * This component is for all sites except kongres.def.dk
 *
 *
 */

export const NewsListFeature: React.FC<Umbraco.NewsList> = ({ content }) => {
	const [news, setNews] = useState<Models.NewsCard[]>([]);
	const impersonateId = useImpersonateId();
	const personalize = content?.properties?.personalize ?? false;

	useEffect(() => {
		const count = content?.properties?.count ? content.properties.count : 999;
		const tags = content?.properties?.tags ? content.properties.tags.map((tag) => tag.id) : null;

		if (personalize) {
			getPersonalizedTagBasedNews(getCMSHostname(), tags, count, impersonateId).then((news) => {
				setNews(news);
			});
		} else {
			getTagBasedNews(getCMSHostname(), tags, count, impersonateId).then((news) => {
				setNews(news);
			});
		}
	}, [content, impersonateId, personalize]);

	return <NewsList {...content?.properties} newsItems={news} />;
};
